import { ContentHeader, StoryblokLink } from '@/queries/schema.generated'
import React, { FunctionComponent, useMemo } from 'react'
import { theme } from '@damen/ui'
import { useBreadcrumb } from '@/hooks'

interface Props {
  header?: ContentHeader & { breadcrumb?: { title?: string; link?: StoryblokLink } }
  componentLookup: Record<string, FunctionComponent<any>>
}

// Lookup for typenames because multiple typenames can be mapped to the same component and the typename is not always the same as the component name
const typenameLookup: Record<string, string> = {
  HeaderError: 'HeaderError',
  HeaderVideo: 'VideoHeader',
  HeaderPrimary: 'PrimaryHeader',
  HeaderSecondary: 'SecondaryHeader',
  HeaderSecondaryNoImage: 'SecondaryHeader',
  HeaderSplit: 'SecondaryHeader',
  HeaderSecondaryDeepDive: 'SecondaryHeader',
  HeaderSecondaryText: 'SecondaryHeader',
  HeaderHome: 'HomeHeader',
}

export const HeaderZone = ({ header, componentLookup }: Props) => {
  const renderedHeader = useMemo(() => {
    if (!header) {
      return null
    }

    const { __typename } = header
    const headerTypeNotUnknown = __typename !== 'Unknown'

    if (headerTypeNotUnknown) {
      const props: Record<string, unknown> = {
        header,
      }

      const breadcrumb = useBreadcrumb(
        __typename !== 'HeaderVideo' ? header?.breadcrumb?.title : '',
        __typename !== 'HeaderVideo' ? header?.breadcrumb?.link : null,
        __typename === 'HeaderSecondaryNoImage' ? 'marineBlack' : 'white',
        __typename !== 'HeaderPrimary' && __typename !== 'HeaderSplit' ? theme.colors.marineBlack : theme.colors.white,
      )

      const __mappedTypename = typenameLookup[__typename]

      const CurrentComponent = componentLookup[__mappedTypename]

      return (
        <CurrentComponent
          {...props}
          content={{
            id: header.id,
            header: { ...header, breadcrumb },
          }}
        />
      )
    }
  }, [header])

  return <>{renderedHeader}</>
}
